$progress-color: #975d2a;

#nprogress .bar {
  background: $progress-color;
}

#nprogress .peg {
  box-shadow: 0 0 10px $progress-color, 0 0 5px $progress-color;
}

#nprogress .spinner-icon {
  border-top-color: $progress-color;
  border-left-color: $progress-color;
}

.top {
  height: 3.06rem;
  padding: 0 1.22rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time {
    font-size: 0.88rem;
    color: #666666;
  }
  .btnCheck {
    width: 4.38rem;
    height: 1.38rem;
    border-radius: 0.69rem;
    border: solid 0.03rem rgba(143, 95, 52, 0.5);
    font-size: 0.94rem;
    color: #8f5f34;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .status {
    font-size: 0.94rem;
    color: #8f5f34;
    &.red {
      color: #f23030;
    }
  }
  .red {
    color: #f23030;
  }
}

.bottom {
  margin-top: 0.31rem;
  height: 4.75rem;
  padding: 0 1.22rem;
  .name {
    font-size: 1.13rem;
    line-height: 1;
    color: #333333;
    margin: 1.47rem 0 0.63rem;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .phone {
      font-size: 0.94rem;
      color: #999999;
    }
    .type {
      font-size: 0.75rem;
      color: #999999;
    }
  }
}

.bookingItem {
  position: relative;
  .imgChecked {
    width: 3.96rem;
    height: 3.96rem;
    position: absolute;
    z-index: 1;
    top: 0.63rem;
    right: 1.22rem;
  }
}
